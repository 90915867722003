import Dexie from 'dexie';
import { Deployment } from './entity/Deployment';
import { Device } from './entity/Device';
import { Option } from './entity/Option';
import { Protocol } from './entity/Protocol';
import { Trial } from './entity/Trial';
import { NewEntity } from './entity/NewEntity';
import { DeviceType } from './entity/DeviceType';
import { AssetTracking } from './entity/AssetTracking';
import { AssetTrackingIssue } from './entity/AssetTrackingIssue';
import { Company } from './entity/Company';
import { AssetTrackingAssetType } from './entity/AssetTrackingAssetType';
import { AssetTrackingModel } from './entity/AssetTrackingModel';
import { AssetTrackingBrand } from './entity/AssetTrackingBrand';
import { AssetTrackingIssuechoice } from './entity/AssetTrackingIssuechoices';

export class Db extends Dexie {

  public deployment!: Dexie.Table<Deployment, number>;
  public device!: Dexie.Table<Device, string>;
  public option!: Dexie.Table<Option, number>;
  public deviceType!: Dexie.Table<DeviceType, number>;
  public protocol!: Dexie.Table<Protocol, string>;
  public trial!: Dexie.Table<Trial, number>;
  public newEntity!: Dexie.Table<NewEntity, number>;
  public assetTracking!: Dexie.Table<AssetTracking, string>;
  public assetTrackingIssue!: Dexie.Table<AssetTrackingIssue, string>;
  public assetTrackingAssetType!: Dexie.Table<AssetTrackingAssetType, number>;
  public assetTrackingModel!: Dexie.Table<AssetTrackingModel, number>;
  public assetTrackingBrand!: Dexie.Table<AssetTrackingBrand, number>;
  public assetTrackingIssuechoice!: Dexie.Table<AssetTrackingIssuechoice, number>;
  public company!: Dexie.Table<Company, number>;

  constructor() {
    if (process.env.VUE_APP_TESTING) {
      super('iotDeploymentV2-testing');
    } else {
      super('iotDeploymentV2');
    }

    this.version(6).stores({
      deployment: 'deploymentId, deviceId, trialId, trialName',
      device: 'deviceId',
      deviceType: 'deviceTypeId',
      option: 'optionId, optionType',
      protocol: 'protocolId',
      trial: 'trialId, protocolId, [name+companyId], [protocolId+companyId], companyId, endDate',
      assetTracking: 'deviceId',
      assetTrackingIssue: '[deviceId+issueId], deviceId',
      assetTrackingAssetType: 'typeId',
      assetTrackingIssuechoice: 'issueId',
      assetTrackingBrand: 'brandId',
      assetTrackingModel: 'modelId',
      newEntity: '++order',
      company: 'companyId',
    });
  }
}
