
import { Component, Vue } from 'vue-property-decorator';
import { Data } from './assets/ts/Data';

export default class IndexComponent extends Vue {

  get hasUnsyncedData(): boolean {
    return false; // this.$store.state.hasUnsyncedData;
  }

  public async created() {
    if (window.location.href.includes('saveDeployment')) {
      // if somebody presses reload after saving
      // TODO: we could do that after any reload
      window.location.href = this.getIndexUrl();
    }
  }

  public refresh() {
    this.$router.push({ name: 'sync' })
      .catch((err) => { window.location.reload(); });
  }

  private getIndexUrl(): string {
    const urlParams = new URLSearchParams(window.location.search);
    let eui = urlParams.get('eui');
    if (eui) {
      eui = '?eui=' + eui;
    } else {
      eui = '';
    }
    return window.location.protocol + '//'
      + window.location.host +  window.location.pathname + eui;
  }
}
