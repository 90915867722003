import ClientOAuth2, { Token } from 'client-oauth2';
import * as OauthToken from '@/assets/ts/entity/OauthToken';


export default class OauthClient {
  private oauthClient = new ClientOAuth2({
    clientId: 'pwdClient',
    clientSecret: 'z2PNWyCMx6ERRw33HiZdd26',
    accessTokenUri: process.env.VUE_APP_BACKEND_URL + '/oauth/token',
  });

  public async login(username: string, password: string): Promise<boolean> {
    let userToken;
    try {
      userToken = await this.oauthClient.owner.getToken(username, password);
    } catch (error) {
      return false;
    }
    this.storeToken(userToken);
    // TODO: check if login successfull or not
    return true;
  }

  public async logout() {
    this.deleteToken();
  }

  /*
  * Return false when there is no valid tokken. But also returns false when the user
  * is offline. Since the valid tokken can NOT be verified.
  */
  public async hasValidLogin(): Promise<boolean> {
    let token = this.getToken();
    if (token) {
      try {
      token = await token.refresh();
      } catch {
        // we assume the refresh didn't work. (the same problem happens if we aren't online anymore)
        // TODO: should we override the previous tokken to delete it?
        return false;
      }
      this.storeToken(token);
      return true;
    } else {
      return false;
    }
  }

  public getToken(): Token | undefined {
    const res = localStorage.getItem('oauthTokken');
    if (res) {
      return this.oauthClient.createToken(JSON.parse(res));
    } else {
      return undefined;
    }
  }

  public getCompanyId(): number | undefined {
    const token = this.getToken();
    if (token) {
      return Number(token.data.companyId);
    } else {
      return undefined;
    }
  }

  public getUsername(): string | undefined {
    const token = this.getToken();
    if (token) {
      return token.data.username;
    } else {
      return undefined;
    }
  }

  public getScope(): string | undefined {
    const token = this.getToken();
    if (token) {
      return token.data.scope;
    } else {
      return undefined;
    }
  }

  private storeToken(token: Token) {
    localStorage.setItem('oauthTokken', JSON.stringify(token.data));
  }

  private deleteToken() {
    localStorage.removeItem('oauthTokken');
  }
}
