import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { Device } from './entity/Device';
import { Deployment } from './entity/Deployment';
import { Protocol } from './entity/Protocol';
import { Trial } from './entity/Trial';
import { Option } from './entity/Option';
import { DeviceType } from './entity/DeviceType';
import { AssetTracking } from './entity/AssetTracking';
import { AssetTrackingIssue } from './entity/AssetTrackingIssue';
import OauthClient from '@/assets/ts/OauthClient';
import { Company } from './entity/Company';
import { AssetTrackingAssetType } from './entity/AssetTrackingAssetType';
import { AssetTrackingModel } from './entity/AssetTrackingModel';
import { AssetTrackingBrand } from './entity/AssetTrackingBrand';
import { AssetTrackingIssuechoice } from './entity/AssetTrackingIssuechoices';
import { DeploymentDto } from './entity/DeploymentDto';

export class RestClient {
  // todo: there is still a lot of naming left from the old application...
  // private baseUrl: string = 'http://localhost/iotDeployment/backend/rest';
  private baseUrl: string = process.env.VUE_APP_REST_URL!;

  public async getDevices(): Promise<Device[]> {
    return await this.getData('/devices', true, true);
  }

  public async getDeployments(): Promise<Deployment[]> {
    return await this.getData('/deployments', true, true);
  }

  public async getProtocols(): Promise<Protocol[]> {
    return await this.getData('/protocols', true, true);
  }

  public async getTrials(): Promise<Trial[]> {
    return await this.getData('/trials', true, true);
  }

  public async getDeviceTypes(): Promise<DeviceType[]> {
    return await this.getData('/deviceTypes', true, true);
  }

  public async getOptions(): Promise<Option[]> {
    return await this.getData('/options', true, true);
  }

  public async getOptionNoInfo(): Promise<Option> {
    return await this.getData('/optionNoInfo', true, true);
  }

  public async getAssetTrackings(): Promise<AssetTracking[]> {
    return await this.getData('/assettrackingAssets', true, true);
  }

  public async getAssetTrackingIssues(): Promise<AssetTrackingIssue[]> {
    return await this.getData('/assettrackingAssetIssues', true, true);
  }

  public async getAssetTrackingAssetTypes(): Promise<AssetTrackingAssetType[]> {
    return await this.getData('/assettrackingTypes', true, true);
  }

  public async getAssetTrackingIssuechoices(): Promise<AssetTrackingIssuechoice[]> {
    return await this.getData('/assettrackingIssues', true, true);
  }

  public async getAssetTrackingModels(): Promise<AssetTrackingModel[]> {
    return await this.getData('/assettrackingModels', true, true);
  }

  public async getAssetTrackingBrands(): Promise<AssetTrackingBrand[]> {
    return await this.getData('/assettrackingBrands', true, true);
  }

  public async updateDeployment(deployment: DeploymentDto) {
    await this.postData('/deployment', deployment);
  }

  public async updateAssetTracking(assetTracking: AssetTracking) {
    await this.postData('/assettrackingAsset', assetTracking);
  }

  /*
  * Send all the actual issues. All the previous ones are deleted on the server
  */
  public async updateAssetTrackingIssues(deviceId: string, assetTrackingIssues: AssetTrackingIssue[]) {
    await this.postData('/assettrackingAssetIssue?deviceId=' + deviceId, assetTrackingIssues);
  }

  public async undeployDevice(device: Device) {
    await this.postData('/undeployDevice', device);
  }

  public async insertTrial(trial: Trial): Promise<Trial> {
    return await this.postData('/trial', trial);
  }

  public async endTrial(trial: Trial) {
    await this.postData('/endTrial', trial);
  }

  /*
  * Checks if webservice is available!
  */
  public async isAvailable(): Promise<boolean> {
    try {
      await this.getData('/ping', false, true);
      return true;
    } catch (error) {
        return false;
    }
  }

  private async postData(subUrl: string, object: any): Promise<any> {
    const options : AxiosRequestConfig = {
      method: 'POST',
      url: this.baseUrl + subUrl,
      data: object,
      //json: true, // Automatically stringifies the body to JSON
    };
    this.signRequest(options);
    const response = await axios(options);
    return response.data;
  }

  private async getData(subUrl: string, signRequest = true, throwError = false) {
    const options : AxiosRequestConfig = {
      url: this.baseUrl + subUrl,
    };
    if (signRequest) {
      this.signRequest(options);
    }
    let result: any;
    try {
        // it's ugly and everything. But we haven't got any error handling... for not authroized get requests... we just
        // ignore it...
        const response = await axios(options);
        result = response.data;
    } catch (e: any) {
      const error = <AxiosError> e;
      if (throwError && error.response?.status != 403) {
        // we ignore if we don-t have the permission to get certain data...
        throw e;
      }
      result = [];
    }
    return result;
  }

  private signRequest(requestOptions: AxiosRequestConfig) {
    const authClient = new OauthClient();
    const token = authClient.getToken();
    if (token) {
      if (!requestOptions.headers) {
        requestOptions.headers = {};
      }
      requestOptions.headers.Authorization = 'Bearer ' + token!.accessToken;
    }
  }

}
