import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
// TODO: Make if freaking ts compliant. with types and everything
// see either: vuex-class-component or direct-vuex or vuex-module-decorators

// TODO: don't load all of this things in every view again! make an object containing them all, and reload
// that thing over and over again....
export default new Vuex.Store({
  state: {
    deviceId: null,
    device: null,
    protocol: null,
    trial: null,
    useProtocol: true,
    isNewTrial: false,
    deployment: null,
    hasUnsyncedData: false,
    undeployAllDevices: false,
  },
  mutations: {
      setDeviceId(state, id) {
        state.deviceId = id;
      },
      setDevice(state, device) {
        state.device = device;
      },
      setProtocol(state, protocol) {
        state.protocol = protocol;
      },
      setTrial(state, trial) {
        state.trial = trial;
      },
      setIsNewTrial(state, isNewTrial) {
        state.isNewTrial = isNewTrial;
      },
      setDeployment(state, deployment) {
        state.deployment = deployment;
      },
      setHasUnsyncedData(state, hasUnsyncedData) {
        state.hasUnsyncedData = hasUnsyncedData;
      },
      setUseProtocol(state, useProtocol) {
        state.useProtocol = useProtocol;
      },
      setUndeployAllDevices(state, undeployAll) {
        state.undeployAllDevices = undeployAll;
      },
  },
  actions: {

  },
});
