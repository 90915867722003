
import 'expose-loader?exposes=$,jQuery!jquery';
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/core.js';
import 'jquery-ui/ui/widgets/dialog.js';
import { Component, Vue } from 'vue-property-decorator';
import { RestClient } from '../assets/ts/RestClient';
import { Data } from '../assets/ts/Data';
import OauthClient from '../assets/ts/OauthClient';
import * as scopes from '../assets/ts/OauthScopes';
import Header from '@/components/Header.vue';

@Component({components: {
  Header,
}})
export default class IndexComponent extends Vue {
  public eui: string | null = null;
  public data: Data = new Data(this.$store);
  public errorMessage: string | null = null;
  public undeployAll = false;

  public async created() {
    // we don't allow offline storage anymore.
    // set that here to be sure, we don-t get message about unsynced data
    // remove that, once we-ve removed all references
    this.$store.commit('setHasUnsyncedData', false);

    // TODO: refactor. the whole thing is unreadable!
    const authClient = new OauthClient();
    if (await this.data.isOnline() && ! await authClient.hasValidLogin()) {
      this.$router.push({ name: 'login' });
      return;
    }
    const authScope = authClient.getScope()!;

    // we should be able to access the query parameters like that:
    // this.$route.query.eui;
    // but it doesn't work. so we do this hack
    const urlParams = new URLSearchParams(window.location.search);
    this.eui = urlParams.get('eui');
    // before we do anything else, sync data!
    if (!(await this.data.syncData())) {
      this.errorMessage = 'You need to be online to activate a Device.';
      return;
    }

    // if we didn't get an eui. don't do anything.
    if (!this.eui) {
      this.errorMessage = 'No EUI specified';
      return;
    }

    // only syngenta uses protocols
    if (authScope.includes(scopes.SCOPE_SYNGENTA)) {
      this.$store.commit('setUseProtocol', true);
    }  else {
      this.$store.commit('setUseProtocol', false);
    }

    // If the device doesn't exist yet. create it.
    // otherwise offer to deply or undeply it.
    this.$store.commit('setDeviceId', this.eui);
    const device = await this.data.getDevice(this.eui);
    if (!device) {
        // New devices can only be created from iotui
        this.errorMessage = 'This device does not exist.';
    } else {
      // Device exists. Only allow admin or the owner company to access the device
      if (!authScope.includes(scopes.SCOPE_ADMIN) && device!.companyId !== authClient.getCompanyId()) {
        this.errorMessage = 'You don\'t have the permission to edit that sensor.';
      } else if (authScope.includes(scopes.SCOPE_ADMIN) && !device!.companyId) {
        this.errorMessage = 'The sensor does not have a company assigned. You can not edit it.';
      } else {
        this.$store.commit('setDevice', device);
        const deviceType = await this.data.getDeviceType(device.deviceTypeId);
        if (deviceType!.isAssetTracking) {
          if (authScope.includes(scopes.SCOPE_ASSET_TRACKING) || authScope.includes(scopes.SCOPE_ADMIN)) {
            const asset = await this.data.getAssetTracking(device.deviceId);
            if (!asset || !asset.isActive) {
              this.showTrackAssetConfirm();
            } else {
              this.$router.replace({ name: 'editAssetTracking' });
            }
          } else {
                    this.errorMessage = 'You don\'t have the permission to edit that sensor.';
          }
        } else {
          if (authScope.includes(scopes.SCOPE_SYNGENTA) || authScope.includes(scopes.SCOPE_ADMIN)
          || authScope.includes(scopes.SCOPE_LORASOIL)) {
            const deployment = await this.data.getDeploymentByDeviceId(
              device.deviceId,
            );
            if (deployment) {
              this.showDialog(true);
            } else {
              this.showDialog(false);
            }
          } else {
            this.errorMessage = 'You don\'t have the permission to edit that sensor.';
          }
        }
      }
    }
  }

  // it's pretty ugly. we basically just copied the previous javascript file here

private showUndeployConfirm() {
    let buttonCancel = true;
    const dialogId: string = '#dialog-confirm-undeploy';
    const router = this.$router;
    const tmpThis = this;
    $(() => {
      $(dialogId).dialog({
        resizable: false,
        height: 'auto',
        width: 'auto',
        position: { my: 'middle', at: 'middle', of: window },
        modal: true,
        buttons: {
          Yes() {
            buttonCancel = false;
            $(this).dialog('close');
            tmpThis.$store.commit('setUndeployAllDevices', tmpThis.undeployAll);
            router.replace({ name: 'undeployDevice'});
          },
          Cancel() {
            $(this).dialog('close');
          },
        },
        close(event: any, ui: any) {
          if (buttonCancel) {
            // document.getElementById("form").innerHTML = "";
          }
        },
      });
    });
  }

  private showTrackAssetConfirm() {
    let buttonCancel = true;
    const dialogId: string = '#dialog-track-asset';
    const router = this.$router;
    $(() => {
      $(dialogId).dialog({
        resizable: false,
        height: 'auto',
        width: 'auto',
        position: { my: 'middle', at: 'middle', of: window },
        modal: true,
        buttons: {
          Yes() {
            buttonCancel = false;
            $(this).dialog('close');
            router.replace({ name: 'editAssetTracking' });
          },
          Cancel() {
            $(this).dialog('close');
          },
        },
        close(event: any, ui: any) {
          if (buttonCancel) {
            // document.getElementById("form").innerHTML = "";
          }
        },
      });
    });
  }

  private showDialog(isAlreadyDeployed: boolean) {
    let buttonCancel: boolean = true;
    let dialogId: string;
    let buttons: any;
    const router = this.$router;
    const tmpThis = this;
    if (isAlreadyDeployed) {
      dialogId = '#dialog-undeploy';
      buttons = {
        'Deactivate'() {
          buttonCancel = false;
          $(this).dialog('close');
          tmpThis.showUndeployConfirm();
        },
        'Deactivate all sensors in this trial'() {
          buttonCancel = false;
          $(this).dialog('close');
          tmpThis.undeployAll = true;
          tmpThis.showUndeployConfirm();
        },
        'Cancel'() {
          $(this).dialog('close');
        },
      };
    } else {
      dialogId = '#dialog-deploy';
      buttons = {
        Activate() {
          buttonCancel = false;
          $(this).dialog('close');
          router.replace({ name: 'selectProtocol' });
        },
        Cancel() {
          $(this).dialog('close');
        },
      };
    }
    $(() => {
      $(dialogId).dialog({
        resizable: false,
        height: 'auto',
        width: 'auto',
        position: { my: 'middle', at: 'middle', of: window },
        modal: true,
        buttons,
        close(event: any, ui: any) {
          if (buttonCancel) {
            // document.getElementById("form").innerHTML = "";
          }
        },
      });
    });
  }
}
