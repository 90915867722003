
import { Component, Vue, Prop } from 'vue-property-decorator';
import OauthClient from '../assets/ts/OauthClient';

@Component
export default class Login extends Vue {
  public username: string = '';
  public password: string = '';
  public isInvalidLogin = false;

  public async login() {
    const authClient = new OauthClient();
    if (await authClient.login(this.username, this.password)) {
      // successfull
      // this.$router.back();
      this.$router.replace({ name: 'index' });
    } else {
      this.isInvalidLogin = true;
      this.password = '';
    }
  }
}
