
import { Component, Vue, Prop } from 'vue-property-decorator';
import OauthClient from '../assets/ts/OauthClient';

@Component
export default class Header extends Vue {
  public username: string | undefined;
  public async created() {
    const authClient = new OauthClient();
    this.username = authClient.getUsername();
  }

  public async logout() {
    const authClient = new OauthClient();
    authClient.logout();
    this.$router.replace({ name: 'logout' });
  }
}
