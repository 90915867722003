import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import store from './store';
import { Data } from './assets/ts/Data';

if (navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for (const registration of registrations) {
      if (registration.active || registration.waiting) {
        registration.unregister();
        window.location.reload();
      } else {
        registration.unregister();
      }
    }
  });
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
