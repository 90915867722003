import 'expose-loader?exposes=$,jQuery!jquery';
import { Store } from 'vuex';
import { Db } from './Db';
import { AssetTracking } from './entity/AssetTracking';
import { AssetTrackingAssetType } from './entity/AssetTrackingAssetType';
import { AssetTrackingBrand } from './entity/AssetTrackingBrand';
import { AssetTrackingIssue } from './entity/AssetTrackingIssue';
import { AssetTrackingIssuechoice } from './entity/AssetTrackingIssuechoices';
import { AssetTrackingModel } from './entity/AssetTrackingModel';
import { Deployment } from './entity/Deployment';
import { DeploymentDto } from './entity/DeploymentDto';
import { Device } from './entity/Device';
import { DeviceType } from './entity/DeviceType';
import { Option } from './entity/Option';
import { Protocol } from './entity/Protocol';
import { Trial } from './entity/Trial';
import { RestClient } from './RestClient';


export class Data {

  private db: Db;
  private rest: RestClient;
  private store: Store<any>;

  // todo. we could get rid of that any, by doing it properly, see
  // https://github.com/istrib/vuex-typescript/
  constructor(store: Store<any>) {
    this.db = new Db();
    this.rest = new RestClient();
    this.store = store;
    // TODO: we only have the if here, becasue larasoil-export which will not provide a store...
    // if (this.store) {
    //   this.setUnsyncedData();
    // }
  }

  public formIsValid(): boolean {
    let isDataValid = true;

    jQuery('.validate').each(function() {
      if (!(this as HTMLFormElement).reportValidity()) {
        this.focus();
        isDataValid = false;
        return false;
      }
    });
    return isDataValid;
  }

  public async syncData(): Promise<boolean> {
    try {
      // await this.syncDataBack();
      await this.cacheAllData();
      return true;
    } catch (error) {
      return false;
      // console.log(error);
      // we couldn't care less.. propably not online and couldn't sync!
      // log to fucking console
    }
    // this.setUnsyncedData();
  }

  public async getDeploymentByDeviceId(deviceId: string): Promise<Deployment | undefined> {
    let deployments = await this.db.deployment.where('deviceId').equals(deviceId).toArray();
    deployments = deployments.filter((deployment) => deployment.timestampUndeployment == null);
    // there should only be one result!
    if (deployments.length > 0) {
      return deployments[0];
    } else {
      return undefined;
    }
  }

  public async getPreviousDeploymentByDeviceId(deviceId: string): Promise<Deployment | undefined> {
    let deployments = await this.db.deployment.where('deviceId').equals(deviceId).sortBy('timestampDeployment');
    // shouldn-t be necessary. When we use this method here, there shouldn-t be a prev deployment which is still active
    deployments = deployments.filter((deployment) => deployment.timestampUndeployment != null);
    // there should only be one result!
    if (deployments.length > 0) {
      return deployments[deployments.length -1];
    } else {
      return undefined;
    }
  }

  public async getDevice(id: string): Promise<Device | undefined> {
    return await this.db.device.get(id);
  }

  public async getDeviceTypes(): Promise<DeviceType[]> {
    return await this.db.deviceType.toArray();
  }

  public async getDeviceType(id: number): Promise<DeviceType | undefined> {
    return await this.db.deviceType.get(id);
  }

  public async getOptions(): Promise<Option[]> {
    return await this.db.option.toArray();
  }

  public async getOptionsByType(dropdownFieldName: string,
                                withOptionNoInfo: boolean,
                                withEmptyOption: boolean): Promise<Option[]> {
    const optList = await this.db.option.where('optionType').equals(dropdownFieldName).toArray();
    if (withOptionNoInfo) {
      const optNo: Option = await this.getOptionNoInfo();
      optList.unshift(optNo);
    }
    if (withEmptyOption) {
      const emptyOption: Option = {
        optionId: null,
        optionType: dropdownFieldName,
        isActive: true,
        value: '',
      };
      optList.unshift(emptyOption);
    }
    return optList;
  }

  public async getOptionNoInfo(): Promise<Option> {
    const options = await this.db.option.where('optionType').equals('no_info').toArray();
    return options[0];
  }

  public async getProtocols(): Promise<Protocol[]> {
    return await this.db.protocol.toArray();
  }

  public async getProtocol(protocolId: string): Promise<Protocol | undefined> {
    return await this.db.protocol.get(protocolId);
  }

  public async getTrials(): Promise<Trial[]> {
    return await this.db.trial.toArray();
  }

  public async getTrial(trialId: number): Promise<Trial | undefined> {
    return await this.db.trial.get(trialId);
  }

  public async getTrialByNameAndCompany(trialName: string, companyId: number): Promise<Trial | undefined> {
    const trials = await this.db.trial.where({ name: trialName, companyId }).toArray();
    if (trials.length > 0) {
      return trials[0];
    } else {
      return undefined;
    }
  }

  public async getTrialsByProtocolAndCompany(protocolId: string, companyId: number): Promise<Trial[]> {
    // indexedDb doesn't support indexes for null values :-(
    return await this.db.trial.where({ protocolId, companyId })
      .filter((item) => item.endDate == null)
      .toArray();
  }

  public async isInactiveTrial(trialName: string, companyId: number): Promise<boolean> {
    return (await this.db.trial.where({ name: trialName, companyId })
      .filter((item) => item.endDate != null)
      .count()) > 0;
  }

  public async getTrialsByCompany(companyId: number): Promise<Trial[]> {
    return await this.db.trial.where({ companyId })
      .filter((item) => item.endDate == null)
      .toArray();
  }

  public async getAssetTracking(deviceId: string): Promise<AssetTracking> {
    let aTracking = await this.db.assetTracking.get(deviceId);
    if (!aTracking) {
      aTracking = {} as AssetTracking;
      aTracking.deviceId = deviceId;
      // await this.updateAssetTracking(aTracking);
    }
    return aTracking;
  }

  public async getAssetTrackingIssues(deviceId: string): Promise<AssetTrackingIssue[]> {
    return await this.db.assetTrackingIssue.where('deviceId').equals(deviceId).toArray();
  }

  public async getAssetTrackingAssetTypes(): Promise<AssetTrackingAssetType[]> {
    return await this.db.assetTrackingAssetType.toArray();
  }

  public async getAssetTrackingAssetIssuechoices(): Promise<AssetTrackingIssuechoice[]> {
    return await this.db.assetTrackingIssuechoice.toArray();
  }

  public async getAssetTrackingBrands(): Promise<AssetTrackingBrand[]> {
    return await this.db.assetTrackingBrand.toArray();
  }

  public async getAssetTrackingModels(): Promise<AssetTrackingModel[]> {
    return await this.db.assetTrackingModel.toArray();
  }

  public async updateAssetTracking(aTracking: AssetTracking) {
    try {
      await this.rest.updateAssetTracking(aTracking);
      this.db.transaction('rw', this.db.assetTracking,
        async () => {
          this.db.assetTracking.put(aTracking);
        });
    } catch (error) {
      // await this.db.transaction('rw', this.db.assetTracking,
      //   this.db.newEntity, async () => {
      //     await this.db.assetTracking.put(aTracking);
      //     const entity = {} as NewEntity;
      //     entity.id = aTracking.deviceId;
      //     entity.type = 'assetTracking';
      //     await this.db.newEntity.add(entity);
      //   });
      // this.setUnsyncedData();
    }
  }

  public async updateAssetTrackingIssues(deviceId: string, assetTrackingIssues: AssetTrackingIssue[]) {
    try {
      await this.rest.updateAssetTrackingIssues(deviceId, assetTrackingIssues);
      this.db.transaction('rw', this.db.assetTrackingIssue,
        async () => {
          await this.db.assetTrackingIssue.where('deviceId').equals(deviceId).delete();
          await this.db.assetTrackingIssue.bulkAdd(assetTrackingIssues);
        });
    } catch (error) {
      // await this.db.transaction('rw', this.db.assetTrackingIssue,
      //   this.db.newEntity, async () => {
      //     await this.db.assetTrackingIssue.where('deviceId').equals(deviceId).delete();
      //     await this.db.assetTrackingIssue.bulkAdd(assetTrackingIssues);
      //     const entity = {} as NewEntity;
      //     entity.id = deviceId;
      //     entity.type = 'assetTrackingIssue';
      //     await this.db.newEntity.add(entity);
      //   });
      // this.setUnsyncedData();
    }
  }

  public async updateDeployment(deployment: DeploymentDto) {
    try {
      await this.rest.updateDeployment(deployment);
      return true;
      // TODO: deployment should be written to database
    } catch (error) {
      return false;
      // await this.db.transaction('rw', this.db.deployment,
      //   this.db.newEntity, async () => {
      //     deployment.deploymentId = await this.getNewDeploymentId();
      //     await this.db.deployment.add(deployment);
      //     const entity = {} as NewEntity;
      //     entity.id = deployment.deploymentId;
      //     entity.type = 'deployment';
      //     await this.db.newEntity.add(entity);
      //   });
      // this.setUnsyncedData();
    }
  }

  public async endTrial(trial: Trial): Promise<boolean> {
    try {
      await this.rest.endTrial(trial);
      return true;
    } catch (error) {
      return false;
      // success = false;
    }
  }

  public async undeployDevice(device: Device): Promise<boolean> {
    const success = true;
    try {
      await this.rest.undeployDevice(device);
      return true;
    } catch (error) {
      return false;
      // success = false;
    }
    // await this.db.transaction('rw',
    //   this.db.newEntity,
    //   this.db.deployment,
    //   async () => {
    //     const deployment = await this.getDeploymentByDeviceId(device.deviceId);
    //     deployment!.isActive = false;
    //     await this.db.deployment.put(deployment!);
    //     if (!success) {
    //       const entity = {} as NewEntity;
    //       entity.id = device.deviceId;
    //       entity.type = 'undeploy_device';
    //       await this.db.newEntity.add(entity);
    //     }
    //   });
    // if (!success) {
    //   this.setUnsyncedData();
    // }
  }

  public async insertTrial(trial: Trial): Promise<Trial> {
    try {
      // ok that's veeery ugly. but it could be that
      // just before we tried to save a protocol. But Couldn't!
      // to be sure everything's working right, we try to sync first!
      // await this.syncDataBack();
      // TODO: it seems like we don't store the new trial directly to database???
      trial = await this.rest.insertTrial(trial);
    } catch (error) {
      // not doing that anymore. delete sooner or later
      // await this.db.transaction('rw', this.db.trial,
      //   this.db.newEntity, async () => {
      //     trial.trialId = await this.getNewTrialId();
      //     // TODO: we should return the trial. so we can set the proper trialId to the deployment!
      //     this.db.trial.add(trial);
      //     const entity = {} as NewEntity;
      //     entity.id = trial.trialId;
      //     entity.type = 'trial';
      //     this.db.newEntity.add(entity);
      //   });
      // this.setUnsyncedData();
    }
    return trial;
  }

  // Not beeing done anymore. Delete it once everybody is sure we don-t use it anymore
  // public async syncDataBack() {
  //   if (await this.rest.isAvailable()) {
  //     // TODO: we should catch if we loose the connection while syncing!
  //     const newEntities = await this.db.newEntity.orderBy('order').toArray();
  //     for (const newEntity of newEntities) {
  //       switch (newEntity.type) {
  //         case 'deployment':
  //           const deployment = await this.db.deployment.get(newEntity.id);
  //           if (isNaN(Number(deployment!.deploymentId))) {
  //             // it's a new deployment!
  //             // TODO: if the deployment got undeployed later also offine.
  //             // then we loose the reference
  //             deployment!.deploymentId = null;
  //           }
  //           await this.rest.updateDeployment(deployment!);
  //           await this.db.newEntity.delete(newEntity.order);
  //           break;
  //         case 'trial':
  //           const trial = await this.db.trial.get(newEntity.id);
  //           if (isNaN(Number(trial!.trialId))) {
  //             // it's a new deployment!
  //             // TODO: if the deployment got undeployed later also offine.
  //             // then we loose the reference
  //             trial!.trialId = null;
  //           }
  //           const newTrial = await this.rest.insertTrial(trial!);
  //           await this.db.newEntity.delete(newEntity.order);
  //           await this.db.trial.delete(newEntity.id);
  //           await this.db.trial.add(newTrial);
  //           // update trialEndDate stuff with the new id. so the sync works properly afterwards
  //           for (const tmpEntity of newEntities) {
  //             if (tmpEntity.type == 'trialEndDate' && tmpEntity.id == trial!.trialId) {
  //               tmpEntity.id = newTrial.trialId;
  //               // this works because the primary key is 'order'
  //               await this.db.newEntity.put(tmpEntity);
  //             }
  //           }
  //           break;
  //         case 'trialEndDate':
  //           const endedTrial = await this.db.trial.get(newEntity.id);
  //           await this.rest.setTrialEndDate(endedTrial!);
  //           await this.db.newEntity.delete(newEntity.order);
  //           break;
  //         case 'protocol':
  //           const protocol = await this.db.protocol.get(newEntity.id);
  //           await this.rest.insertProtocol(protocol!);
  //           await this.db.newEntity.delete(newEntity.order);
  //           break;
  //         case 'undeploy_device':
  //           const uDevice = await this.db.device.get(newEntity.id);
  //           await this.rest.undeployDevice(uDevice!);
  //           await this.db.newEntity.delete(newEntity.order);
  //           break;
  //         case 'assetTracking':
  //           const aTracking = await this.db.assetTracking.get(newEntity.id);
  //           await this.rest.updateAssetTracking(aTracking!);
  //           await this.db.newEntity.delete(newEntity.order);
  //           break;
  //         case 'assetTrackingIssue':
  //           const issues = await this.db.assetTrackingIssue.where('deviceId').equals(newEntity.id).toArray();
  //           await this.rest.updateAssetTrackingIssues(newEntity.id, issues);
  //           await this.db.newEntity.delete(newEntity.order);
  //           break;
  //         default:
  //           break;
  //       }
  //     }
  //   }
  //   this.setUnsyncedData();
  // }

  public async getAllActiveDeploymentsFromTrial(trial: Trial): Promise<Deployment[]> {
    const deployments = await this.db.deployment.where({ trialName: trial.name }).toArray();
    // unforunately, it could happen, that the trial id is not yet set on the deployments :-(
    // se wee need to verify the companyId too :-()
    // TODO: do that! see other TODOs
    const result: Deployment[] = [];
    for (const deployment of deployments) {
      const device = await this.db.device.get(deployment.deviceId);
      if (deployment.timestampUndeployment == null && device!.companyId === trial.companyId) {
        result.push(deployment);
      }
    }
    return result;
  }

  /*
  * Tells us that we are online. We define as online: We can reach the rest service!
  */
  public async isOnline(): Promise<boolean> {
    return this.rest.isAvailable();
  }

  private async cacheAllData() {
    try {
      // get the scope (the user group the user belongs to)
      // we get all data from the server and cache them!
      // we try to get all data, independently if the user has access to them or not.
      // if he doesn't have access, he will get a reply back telling so.
      // maybe we do that more beautiful in the future.

      const [devices,
        options,
        protocols,
        trials,
        deployments,
        deviceTypes,
        assetTrackings,
        assetTrackingIssues,
        assetTrackingAssetTypes,
        assetTrackingModels,
      ] = await Promise.all([this.rest.getDevices(),
      this.rest.getOptions(),
      this.rest.getProtocols(),
      this.rest.getTrials(),
      this.rest.getDeployments(),
      this.rest.getDeviceTypes(),
      this.rest.getAssetTrackings(),
      this.rest.getAssetTrackingIssues(),
      this.rest.getAssetTrackingAssetTypes(),
      this.rest.getAssetTrackingModels(),
      ]);

      // For whatever freaking reason we can't use more than 10 promises... otherwise
      // typescript can't match the types... maybe in a future typescript version they'll fix tat...
      const [
        assetTrackingBrands,
        assetTrackingIssuechoices,
      ] = await Promise.all([
        this.rest.getAssetTrackingBrands(),
        this.rest.getAssetTrackingIssuechoices(),
      ]);

      // TODO: when ever that bug gets fixed, do the transactions around again!
      // await this.db.transaction('rw',
      //   this.db.device,
      //   this.db.option,
      //   this.db.deployment,
      //   this.db.protocol,
      //   this.db.trial, async () => {
      await this.db.device.clear();
      await this.db.device.bulkAdd(devices);
      await this.db.option.clear();
      await this.db.option.bulkAdd(options);
      await this.db.protocol.clear();
      await this.db.protocol.bulkAdd(protocols);
      await this.db.trial.clear();
      await this.db.trial.bulkAdd(trials);
      await this.db.deployment.clear();
      await this.db.deployment.bulkAdd(deployments);
      // we can'tl ock more than 7 tables for a transactions
      // await this.db.transaction('rw',
      //   this.db.deviceType,
      //   this.db.assetTracking,
      //   this.db.assetTrackingIssue, async () => {
      await this.db.deviceType.clear();
      await this.db.deviceType.bulkAdd(deviceTypes);
      await this.db.assetTracking.clear();
      await this.db.assetTracking.bulkAdd(assetTrackings);
      await this.db.assetTrackingIssue.clear();
      await this.db.assetTrackingIssue.bulkAdd(assetTrackingIssues);
      await this.db.assetTrackingAssetType.clear();
      await this.db.assetTrackingAssetType.bulkAdd(assetTrackingAssetTypes);
      await this.db.assetTrackingIssuechoice.clear();
      await this.db.assetTrackingIssuechoice.bulkAdd(assetTrackingIssuechoices);
      await this.db.assetTrackingModel.clear();
      await this.db.assetTrackingModel.bulkAdd(assetTrackingModels);
      await this.db.assetTrackingBrand.clear();
      await this.db.assetTrackingBrand.bulkAdd(assetTrackingBrands);
    } catch (error) {
      throw error;
    }
  }

  // private async setUnsyncedData() {
  //   if (await this.db.newEntity.count() > 0) {
  //     this.store.commit('setHasUnsyncedData', true);
  //   } else {
  //     this.store.commit('setHasUnsyncedData', false);
  //   }

  // }

  private async getNewDeploymentId() {
    let nmber: any;
    do {
      nmber = Math.floor(Math.random() * 10000000 + 1);
      nmber = 'a' + nmber;
    } while (await this.db.deployment.get(nmber));
    return nmber;
  }

  private async getNewTrialId() {
    let nmber: any;
    do {
      nmber = Math.floor(Math.random() * 10000000 + 1);
      nmber = 'a' + nmber;
    } while (await this.db.trial.get(nmber));
    return nmber;
  }

}
