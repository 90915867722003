import Vue from 'vue';
import Router from 'vue-router';
import Index from './views/Index.vue';
import Login from './views/login.vue';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/undeployDevice',
      name: 'undeployDevice',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('./views/undeployDevice.vue'),
    },
    {
      path: '/selectProtocol',
      name: 'selectProtocol',
      component: () => import('./views/selectProtocol.vue'),
    },
    {
      path: '/editTrial',
      name: 'editTrial',
      component: () => import('./views/editTrial.vue'),
    },
    {
      path: '/editDeployment',
      name: 'editDeployment',
      // tslint:disable-next-line:max-line-length
      component: () => import('./views/editDeployment.vue'),
    },
    {
        path: '/saveDeployment',
        name: 'saveDeployment',
        component: () => import('./views/saveDeployment.vue'),
    },
    {
      path: '/editAssetTracking',
      name: 'editAssetTracking',
      component: () => import('./views/editAssetTracking.vue'),
    },
    {
        path: '/sync',
        name: 'sync',
        component: () => import('./views/sync.vue'),
    },
    {
        path: '/dataUpdated',
        name: 'dataUpdated',
        component: () => import('./views/dataUpdated.vue'),
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('./views/logout.vue'),
    },
  ],
});
